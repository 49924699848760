.Cards {
    @apply grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-[16px] sm:gap-[20px] mt-[30px]
}

.ShopCard {
    @apply w-full bg-[#EAE4ED] rounded-[20px] overflow-hidden relative 
}
.ShopCard .CardPrices {
    @apply absolute top-0 left-0 bg-a-active-0 bg-opacity-40 py-[4px] px-[12px] sm:py-[8px] sm:px-[20px] rounded-br-[20px] 
}
.ShopCard .CardPrice {
    @apply text-[12px] sm:text-[14px] text-white font-bold leading-120
}
.ShopCard .CardPrice.PriceOld {
    @apply line-through
}
.ShopCard .CardActuality {
    @apply absolute left-0 top-[-22px] bg-a-active-0 bg-opacity-40 py-[4px] px-[12px] rounded-tr-[20px]
}
.ShopCard .CardActuality p {
    @apply text-[12px] text-white font-bold leading-120
}
.ShopCard .CardInfo {
    @apply px-[8px] py-[12px] sm:p-[12px] flex flex-col gap-[12px] relative
}
.ShopCard .CardTitle {
    @apply text-[12px] sm:text-[14px] font-bold leading-140 min-h-[60px]
}
.ShopCard .CardColors {
    @apply flex gap-[8px] sm:gap-[10px] flex-wrap cursor-pointer min-h-[26px]
}
.ShopCard .CardBottom {
    @apply flex justify-between
}
.ShopCard .CardBtn {
    @apply py-[4px] px-[6px] sm:p-[8px] rounded-[6px] sm:rounded-[10px] bg-a-pink-0 font-bold text-[12px] sm:text-[14px] cursor-pointer transition-all duration-300 hover:bg-a-buzok-0
}
.ShopCard .CardBasket {
    @apply py-[4px] px-[6px]  sm:py-[8px] sm:px-[16px] rounded-[6px] sm:rounded-[10px] bg-a-active-0 flex items-center hover:bg-opacity-90 cursor-pointer transition-all duration-300
}
.ShopCard .CardBasket img{
    @apply w-[18px] sm:w-[22px]
}
.ShopCard .cardTitleImg {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
}
